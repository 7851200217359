import React, { useState } from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import HeroSingle from '../components/pages/HeroSingle';
import Footer from '../components/Footer';
import Map from '../components/homepage/Map';
import linefull from '../images/linefull.jpg';
import wrtsapp from '../images/wrtsapp.jpg';
import wrtsgoogle from '../images/wrtsgoogle.jpg';
import mobileapp from '../images/mobileapp.jpg';
import SEO from '../components/seo';
import { useAppContent } from '../hooks/getAppContent';

import '../styles/app.scss';

const ContactPage = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");
    const AppData = useAppContent();
    const appDownload = AppData.wordpressPage.acf;

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle="Contact" />

            <section
                className="page-section smallestwdt nopaddbottsec"
                id="contactsec"
            >
                <div className="container">
                    <div className="openplft">
                        <img
                            className="whiteborderimg"
                            src={
                                post.featured_media.localFile.childImageSharp
                                    .fluid.src
                            }
                            alt="cont1"
                        />
                    </div>

                    <div
                        className="openprgt"
                        dangerouslySetInnerHTML={{
                            __html: post.content,
                        }}
                    ></div>
                </div>
                <img className="contline" src={linefull} alt="linefull" />
                <div className="abouthomeapp contapp">
                    <h6
                        className="bluetxt"
                        dangerouslySetInnerHTML={{
                            __html: appDownload.app_download_text,
                        }}
                    />
                    <a
                        href="https://apps.apple.com/us/app/we-rock-the-spectrum-kids-gym/id1204873096"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={wrtsapp} alt="wrtsapp" />
                    </a>
                    {` `}
                    <a
                        href="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.werockthespectrumkidsgym&hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={wrtsgoogle} alt="wrtsgoogle" />
                    </a>
                    <img className="appmob" src={mobileapp} alt="mobileapp" />
                </div>
            </section>

            <section
                className="whywelist page-section bg-primary text-white"
                id="contformsec"
            >
                <h2 className="yellowtxt">
                    Email us if you have any questions
                </h2>
                <div className="container flexwrap contactform_wrap">
                    <iframe
                        src="https://werockthespectrumcincinnati.wrtsfranchise.com/contact/"
                        title="contactform"
                    />
                </div>
            </section>

            <Map />
            <Footer />
        </>
    );
};
export default ContactPage;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            content
            featured_media {
                localFile {
                    childImageSharp {
                        fluid {
                            src
                        }
                    }
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
